import React, { useState, useEffect } from "react";
import Navigation from '../Navigation';
import { Link } from "react-router-dom";
import DocumentationTable from "./DocumentationTable";
import {nrData, lteData, wcdmaData, gsmData, signalMetadata} from "./SignalData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faInfoCircle, faUserCog } from "@fortawesome/free-solid-svg-icons";
import Figure from './Figure';

export default function MobileDocumentation() {

    return (
        <div className='admin-panel-container'>
            <Navigation isFixed={true} />
            <div className="docs-sidebar">
                <h3 className="font-p-semibold">
                    Portal Docs
                </h3>
                <a className="color-red padding-top-1" href='#introduction'>
                    <h5 className="font-p-semibold">
                        Introduction
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#navigation'>
                    <h5 className="font-p-semibold">
                        Navigation
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#user-management'>
                    <h5 className="font-p-semibold">
                        User Management
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#admin-panel'>
                    <h5 className="font-p-semibold">
                        Administrator Panel
                    </h5>
                </a>
                <a className="color-red padding-top-half" href='#signal-data'>
                    <h5 className="font-p-semibold">
                        Signal Data
                    </h5>
                </a>
            </div>
            <div className="docs-wrapper">
                <div className="docs-content-wrapper flex align-center ">
                    <div className="docs-content padding-2">
                        <h2 className="font-p-reg">
                            Web Portal User Documentation
                        </h2>
                        <a className="anchor" id='introduction'>

                        </a>
                        <h3 className="font-p-reg margin-top-2">Introduction</h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                While the LTE Finder Mobile App is used to track and communicate cell signal data to our web portal, the Web Portal is used to interpret, analyze, and to make decisions regarding the cellular signal data your organization has collected.
                            </p>
                        </h5>
                        <a className="anchor" id='navigation'>

                        </a>
                        <h3 className="font-p-reg margin-top-2">
                            Navigation
                        </h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                To open up the Navigation bar, click the User Settings icon <FontAwesomeIcon
                                    style={{ marginRight: '.25rem', marginLeft: '.25rem' }}
                                    color={'#000'}
                                    icon={faUserCog}
                                    size="2x"
                                /> in the top right hand corner of the screen. The Navigation bar will slide open from the right side of the screen. You will then have the following Navigation options:
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        border
                                        path={'/images/portal-docs/user-navigation.png'}
                                        width={300}
                                        label="Navigation Bar"
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    User Management: A page where Super Administrators and Administrators can manage users with access to LTE Finder in their organization.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    Priority Points: A page which allows users to search and analyze Priority Points picked up by their organization.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    Carrier Comparison: A page which allows users to compare cellular coverage between carriers in any given area.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    User Settings: A page which allows users to change their own information.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    Logout: Click this Navigation section to log out of the app.
                                </li>
                            </ol>
                        </div>
                        <a className="anchor" id='user-management'>

                        </a>
                        <h3 className="font-p-reg margin-top-2">
                            User Management
                        </h3>

                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Because any given organization may have numerous individuals who are involved in the cell signal data collection process, LTE Finder provides a complete User Management suite to allow your organization to grant everyone access at the proper permission level.
                            </p>
                        </h5>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Creating Users
                        </h4>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <li className="font-p-semibold directionsListItem">
                                    First, click on the settings panel in the top right handle and select "User Management" this will take you to the User Management page.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        border
                                        label={'User Management Page'}
                                        path={'/images/portal-docs/UserManagementIndex.png'}
                                        width={600}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-2 directionsListItem">
                                    Click the "Create User" button at the top of the page. Once you click this button, a form to create a new user will appear at the top of the page.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        border
                                        label={'User Creation Form'}
                                        path={'/images/portal-docs/UserManagementCreateUser.png'}
                                        width={600}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-2 directionsListItem">First, enter the first and last name of the new user.</li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Next, assign your user a Username. A username must be between 6 and 18 characters long, and may include letters, numbers, and underscores. You may capitalize letters in the username, however all usernames will be converted to all lowercase letters upon creation.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Fill in the User's email address into the Email field. <b className="font-p-bold font-weight-9">It is important that this email address is accessible to the intended User. This is because once you create this account, an email will be sent to that User's email with their login information</b>
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Fill in the User's email address into the Email field. <b className="font-p-bold font-weight-9">It is important that this email address is accessible to the intended User. This is because once you create this account, an email will be sent to that User's email with their login information</b>
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Enter the user's phone number into the Phone Number field.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Fill out the User's User Role. To find out more about which User Role you should select, please go to the User Roll section of the Special Topics section of the User Documentation.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    If you have any internal identifiers with phones that are given to signal surveyors, you may enter it into the Assigned Phone ID field. The Assigned Phone Id field is not required to create a user.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    Once you are satisfied with the values you've entered for your new user, click the "Save" button.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    When you click the save button, you will see a loading icon followed by a feedback message saying that the "User was successfully created". An email will be sent to the new user's email address, containing their username and an autogenerated password so that they can login.
                                </li>
                            </ol>
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Editing Users
                        </h4>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <li className="font-p-semibold directionsListItem">
                                    To edit a User, select the Edit Icon <FontAwesomeIcon
                                        style={{ marginRight: '.25rem', marginLeft: '.25rem' }}
                                        color={'hsl(42, 78%, 60%)'}
                                        icon={faEdit}
                                        size="2x"
                                    /> on the user whose data you would like to edit.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'User Edit Form'}
                                        path={'/images/portal-docs/user-edit-form.png'}
                                        width={600}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-2 directionsListItem">
                                    Update any information that you wish to change.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    After you are satisfied with your changes, click the "Save" button, you will then see a loading icon followed by a "User Updated Successfully" message on the User you just edited.
                                </li>
                            </ol>
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Resetting a User's Password
                        </h4>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <li className="font-p-semibold directionsListItem">
                                    To edit a User, select the Edit Icon <FontAwesomeIcon
                                        style={{ marginRight: '.25rem', marginLeft: '.25rem' }}
                                        color={'hsl(42, 78%, 60%)'}
                                        icon={faEdit}
                                        size="2x"
                                    /> on the user whose password you would like to reset.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'User Edit Form'}
                                        path={'/images/portal-docs/user-edit-form.png'}
                                        width={600}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-2 directionsListItem">
                                    Click the "Reset Password" button
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    A Password Reset confirmation modal will open prompting you if you wish to proceed.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Reset Password Prompt'}
                                        path={'/images/portal-docs/user-reset-prompt.png'}
                                        width={600}
                                    />
                                </div>
                                <li className="font-p-semibold padding-top-2 directionsListItem">
                                    After you have read the Confirmation Prompt, click the green "Proceed" button. You will then see a loading animation and a success message telling you that your Password Reset Request is successful.
                                </li>
                                <li className="font-p-semibold padding-top-half directionsListItem">
                                    The user whose password was reset will recieve an email with their new password.
                                </li>
                            </ol>
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Deleting a User's Account
                        </h4>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <li className="font-p-semibold directionsListItem">
                                    Click the delete button <FontAwesomeIcon
                                        style={{ cursor: 'pointer', marginRight: '.25rem', marginLeft: '.25rem' }}
                                        icon={faTrash}
                                        size="2x"
                                        color={'hsl(360, 83%, 62%)'}
                                    /> for the User whose account you wish to delete.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Delete User Prompt'}
                                        path={'/images/portal-docs/user-delete-prompt.png'}
                                        width={600}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    The User row will prompt you, asking if you are sure if you would like to delete the account.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    Click the red "Delete" button. A loading animation will appear and if the deletion request is successful, the User will be removed from the list of users in your organization.
                                </li>
                            </ol>
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Looking at User Information
                        </h4>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <li className="font-p-semibold directionsListItem">
                                    Click the info icon <FontAwesomeIcon
                                        style={{ marginLeft: '.25rem', marginRight: '.25rem', cursor: 'pointer' }}
                                        color={'hsl(197, 92%, 61%)'}
                                        icon={faInfoCircle}
                                        size="2x"
                                    /> for the User whose account you wish to see.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'User Info Card'}
                                        path={'/images/portal-docs/user-info.png'}
                                        width={600}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    Once the info icon has been clicked, the user card will expand showing information about the user.
                                </li>
                            </ol>
                        </div>
                        <a className="anchor" id='admin-panel'>

                        </a>
                        <h3 className="font-p-reg margin-top-2">
                            Administrator Panel
                        </h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                By far the most important page for Administrators in your organization, the Administrator Panel is LTE Finder's central hub for analyzing organization-wide signal data. This page allows users to see their organization's cellular signals, add filters to see specific data points, and to make decisions based on that data.
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-2">
                            <Figure
                                label={'Admin Panel'}
                                path={'/images/portal-docs/admin-panel-index.png'}
                                width={600}
                            />
                        </div>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                In this section of the Web Portal Docs, we will go over each portion of the Administration Panel so you can understand how to navigate through your cellular signal map.
                            </p>
                        </h5>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Map Modes
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Map Modes allow you to adjust your map results according to what factor you would like to base your cellular signal strengths on. In this section we will go over the numerous options you have when setting your Map Mode.
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <div className="flex w-100 align-center justify-center padding-top-1">
                                    <Figure
                                        label={'Map Mode Controls'}
                                        path={'/images/portal-docs/density-map.png'}
                                        width={250}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    Density Mode: The default Map Mode is Density Mode. Density Mode is not meant for looking at the signal strength or signal quality. Rather, Density Mode is about assessing whether or not you have collected a reliable amount of cellular signals in a given location.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Map in Density Mode'}
                                        path={'/images/portal-docs/density-map.png'}
                                        width={500}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    The second map mode is Quality Mode, which allows Users to view the Map in terms of Cell Signal "Quality". While the "quality" of a cell signal can be interpreted as a very technical term when analyzing cell signals, in this case we mean to be the overall quality on which we can judge if a Cell Signal can give one a serviceable internet connection. When in Quality mode, you can choose for Quality to be interpreted as "dBm" or "Bars". dBm refers to a cellular signals "Decibal per Milliwatt", which is a common method to evaluate Cell Signal quality. The other way you can interpret quality is by "Bars", which is the number of Bars a User's cell phone had while they were collecting that signal. While "Bars" sounds like a very bland method to interpret signals, many times cell phone manufacturers and the Android operating system have used Bars as a way to reduce all of the data about a signal to one quantifiable indicator of quality. For this reason we have chosen to include "Bars" as a quality measure and will be releasing more measures soon.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Map in Quality Mode with respect to dBm'}
                                        path={'/images/portal-docs/map-quality-dbm.png'}
                                        width={500}
                                    />
                                </div>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Map in Quality Mode with respect to bars'}
                                        path={'/images/portal-docs/map-quality-bars.png'}
                                        width={500}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    The final map mode is Carrier Mode. This simply shows the cellular signals based off of what Cell Carrier Network the signal belongs to. This mode is best used in conjunction with various filters to determine Carrier Coverage with respect to other factors.
                                </li>
                                <div className="flex w-100 align-center justify-center padding-top-2">
                                    <Figure
                                        label={'Map in Carrier Mode'}
                                        path={'/images/portal-docs/map-carrier-mode.png'}
                                        width={500}
                                    />
                                </div>
                            </ol>
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Map Filters
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Map Filters allow you to filter signals on or off the map to come to better conclusions about your cell signal data. LTE Finder provides many filters to help you configure your map to properly see cell signal data with respect to the different factors by which you want to differentiate your cellular signal data.
                            </p>
                        </h5>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Signal Type Filters
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                The first filter suite LTE Finder offers is filtering by Signal Type. In this section we will go over the different types of Cell Signals with which you can filter you map with.
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <div className="flex w-100 align-center justify-center padding-top-1">
                                    <Figure
                                        label={'Signal Type Filters'}
                                        path={'/images/portal-docs/signal-type-filters.png'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    5G: 5G includes any signals that are NR Signals. While some LTE Signals can be "interpreted" as a 5G Signal, we are currently only including only NR Signals as part of our 5G Filter.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    4G: 4G includes any signals that are LTE Signals. While some LTE Signals can be "interpreted" as a 5G Signal, we are currently only including only NR Signals as part of our 5G Filter.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    4G: 4G includes any signals that are LTE Signals. While some LTE Signals can be "interpreted" as a 5G Signal, we are currently only including only NR Signals as part of our 5G Filter.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    3G: 3G includes any WCDMA signals. WCDMA Signals are set to be phased out by all major carriers at the end of 2022.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    2G: 2G includes any CDMA or GSM Signals. All major carriers are set to phase out CDMA and GSM signals at the end of 2022.
                                </li>
                            </ol>
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Date Range Filter
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                When you want to filter your signals by a date range in which they were collected, you can use the Date Range filter. To filter by a Date Range, follow the following steps.
                            </p>
                        </h5>
                        <div className="padding-left-1">
                            <ol className="directionsList font-p-reg font-weight-5 padding-top-1-5">
                                <div className="flex w-100 align-center justify-center padding-top-1">
                                    <Figure
                                        label={'Date Range Filter'}
                                        path={'/images/portal-docs/date-mode-filter.png'}
                                        width={300}
                                    />
                                </div>
                                <li className="font-p-semibold directionsListItem padding-top-2">
                                    First toggle the toggle switch labeled "Use Date Ranges for Signal Selections". This will expand the Date Range filter section on your Filter tab.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    Set the Start and End dates you want to filter your cell signals by.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    Once you have selected the Start and End dates, click "Enter". This will filter all the cell signals on your map to only those which were collected within the Date Range specified.
                                </li>
                                <li className="font-p-semibold directionsListItem padding-top-half">
                                    To turn off the Date Range filter, simply refresh the page.
                                </li>
                            </ol>
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Signal Strength Filter
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                When you want to filter signals on your map based upon the quality of the cellular signal, use the Signal Strengths Filter. This will filter the quality of Cellular Signals on the map from Poor to Best. LTE Finder currently uses Bars to determine filtering for Signal Strength (Poor being 1 Bar while Best is 4 Bars)
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-1">
                            <Figure
                                label={'Signal Strength Filters'}
                                path={'/images/portal-docs/signal-strength-filter.png'}
                                width={300}
                            />
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Carrier Filter
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                When you want to filter signals on your map based upon the Cellular Network it belongs to, you can use Carrier Filters. LTE Finder currently supports filters for the Big Three Cell Carriers (AT&T, Verizon, and T-Mobile). Any signals which are provided by smaller carriers who operate on the big three networks (examples are Metro and Cricket Wireless) will be identified as the major network they are on. For example, any Cricket Wireless cell signals will be identified on AT&T, and any Metro cell signals will be identified as belonging to the T-Mobile network. This may change in the future as we add more features to the LTE Finder application.
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-1">
                            <Figure
                                label={'Signal Type Filters'}
                                path={'/images/portal-docs/carrier-filter.png'}
                                width={300}
                            />
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Signal Details
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Whenever you want to see more detailed information about a cellular signal, simply zoom in and click on it on the map. A scrollable popup will appear where you can see all data related to the Cellular Signal. If multiple Cellular Signals are in the location you clicked, each signal will be included in the popup as you scroll through the cellular data.
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-1">
                            <Figure
                                label={'Cell Signal Detail Scrollable Popup'}
                                path={'/images/portal-docs/cell-signal-details.png'}
                                width={160}
                            />
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Searching Different Locations
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                By default, your Map will be centered at your Organization's default location. If you would like to navigate to a different location where you have collected signals, simply use your mouse to drag your map to the desired location. If the location is far enough away from your Organization's default location (100 kilometers), no cellular signals will be loaded by default in that area. To load signals from any area outside of 100km from your default location, scroll to that area and select "Search This Area". Once you click this button, a loading animation will appear, followed by more signals loaded into your map in this location. If your organization has no signals in this location, no signals will be loaded. Once you have searched an area, you must scroll your map at least 100km away from your last searched location before you can search another area.
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-1">
                            <Figure
                                label={'Map with Search Box'}
                                path={'/images/portal-docs/search-map.png'}
                                width={600}
                            />
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            User Table
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Below your map, you will see a list of all Users in your organization and metadata pertaining to each user. To show an Administrative Panel for only one User, click the "Analyze" button in the row which represents the User whose cellular data you wish to view.
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-1">
                            <Figure
                                label={'User Table'}
                                path={'/images/portal-docs/admin-user-table.png'}
                                width={600}
                            />
                        </div>
                        <h4 className="font-p-reg margin-top-2 font-weight-8">
                            Priority Point Table
                        </h4>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                When using the Administrative Panel while analyzing signal data for one user, the table below the map will show that user's priority points instead. All priority points will be marked with a big yellow circle on the map, and will be loaded into the map regardless of whether or not they are within the 100km radius of your organization's default location. To highlight one of the Priority Points, click the "Highlight" button in the table row of the Priority Point you wish to focus on. A highlighted Priority Point will be depicted as a large, green circle on the map. Only one Priority Point may be highlighted at one time.
                            </p>
                        </h5>
                        <div className="flex w-100 align-center justify-center padding-top-1">
                            <Figure
                                label={'Priority Point Table'}
                                path={'/images/portal-docs/admin-priority-points.png'}
                                width={600}
                            />
                        </div>
                        <a className="anchor" id='signal-data'>

                        </a>
                        <h3 id='signal-data' className="font-p-reg margin-top-2 font-weight-8">
                            Signal Information
                        </h3>
                        <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                To better understand your organization's cell signal data, in this section we will provide a list of resources about the Signal Data that you will have access to. 
                            </p>
                        </h5>
                        <div className='padding-sides-1'>
                            <h4 className="font-p-reg margin-top-2 font-weight-8">
                                All Signal Metadata
                            </h4>
                            <h5 className="font-p-light margin-top-1 font-weight-3">
                            <p className="line-height-3">
                                Along with the Signal Data described in the tables below. Every instance of Signal Cell Datum will include the following metadata for the signal.
                            </p>
                        </h5>
                            <div className="flex w-100 align-center justify-center padding-top-1">
                                <DocumentationTable data={signalMetadata}/>
                            </div>
                        </div>
                        <div className="padding-left-1 padding-top-2">
                            <h4 className="font-p-reg margin-top-2 font-weight-8">
                                NR Signal Data
                            </h4>
                            <div className="flex w-100 align-center justify-center padding-top-1">
                                <DocumentationTable data={nrData}/>
                            </div>
                        </div>
                        <div className="padding-left-1 padding-top-2">
                            <h4 className="font-p-reg margin-top-2 font-weight-8">
                                LTE Signal Data
                            </h4>
                            <div className="flex w-100 align-center justify-center padding-top-1">
                                <DocumentationTable data={lteData}/>
                            </div>
                        </div>
                        <div className="padding-left-1">
                            <h4 className="font-p-reg margin-top-2 font-weight-8">
                                WCDMA Signal Data
                            </h4>
                            <div className="flex w-100 align-center justify-center padding-top-1">
                                <DocumentationTable data={wcdmaData}/>
                            </div>
                        </div>
                        <div className="padding-left-1 padding-top-2">
                            <h4 className="font-p-reg margin-top-2 font-weight-8">
                                GSM Signal Data
                            </h4>
                            <div className="flex w-100 align-center justify-center padding-top-1">
                                <DocumentationTable data={gsmData}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div >
    )
}

